// source: NetspeakService.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.netspeak.service.CorporaRequest', null, global);
goog.exportSymbol('proto.netspeak.service.CorporaResponse', null, global);
goog.exportSymbol('proto.netspeak.service.Corpus', null, global);
goog.exportSymbol('proto.netspeak.service.Phrase', null, global);
goog.exportSymbol('proto.netspeak.service.Phrase.Word', null, global);
goog.exportSymbol('proto.netspeak.service.Phrase.Word.Tag', null, global);
goog.exportSymbol('proto.netspeak.service.PhraseConstraints', null, global);
goog.exportSymbol('proto.netspeak.service.SearchRequest', null, global);
goog.exportSymbol('proto.netspeak.service.SearchResponse', null, global);
goog.exportSymbol('proto.netspeak.service.SearchResponse.Error', null, global);
goog.exportSymbol('proto.netspeak.service.SearchResponse.Error.Kind', null, global);
goog.exportSymbol('proto.netspeak.service.SearchResponse.ResponseCase', null, global);
goog.exportSymbol('proto.netspeak.service.SearchResponse.Result', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.SearchRequest.displayName = 'proto.netspeak.service.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.PhraseConstraints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.PhraseConstraints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.PhraseConstraints.displayName = 'proto.netspeak.service.PhraseConstraints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.Phrase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.netspeak.service.Phrase.repeatedFields_, null);
};
goog.inherits(proto.netspeak.service.Phrase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.Phrase.displayName = 'proto.netspeak.service.Phrase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.Phrase.Word = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.Phrase.Word, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.Phrase.Word.displayName = 'proto.netspeak.service.Phrase.Word';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.SearchResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.netspeak.service.SearchResponse.oneofGroups_);
};
goog.inherits(proto.netspeak.service.SearchResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.SearchResponse.displayName = 'proto.netspeak.service.SearchResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.SearchResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.netspeak.service.SearchResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.netspeak.service.SearchResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.SearchResponse.Result.displayName = 'proto.netspeak.service.SearchResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.SearchResponse.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.SearchResponse.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.SearchResponse.Error.displayName = 'proto.netspeak.service.SearchResponse.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.CorporaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.CorporaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.CorporaRequest.displayName = 'proto.netspeak.service.CorporaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.Corpus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.netspeak.service.Corpus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.Corpus.displayName = 'proto.netspeak.service.Corpus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.netspeak.service.CorporaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.netspeak.service.CorporaResponse.repeatedFields_, null);
};
goog.inherits(proto.netspeak.service.CorporaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.netspeak.service.CorporaResponse.displayName = 'proto.netspeak.service.CorporaResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    corpus: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxPhrases: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phraseConstraints: (f = msg.getPhraseConstraints()) && proto.netspeak.service.PhraseConstraints.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.SearchRequest}
 */
proto.netspeak.service.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.SearchRequest;
  return proto.netspeak.service.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.SearchRequest}
 */
proto.netspeak.service.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorpus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxPhrases(value);
      break;
    case 4:
      var value = new proto.netspeak.service.PhraseConstraints;
      reader.readMessage(value,proto.netspeak.service.PhraseConstraints.deserializeBinaryFromReader);
      msg.setPhraseConstraints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorpus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxPhrases();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPhraseConstraints();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.netspeak.service.PhraseConstraints.serializeBinaryToWriter
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.netspeak.service.SearchRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.SearchRequest} returns this
 */
proto.netspeak.service.SearchRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string corpus = 2;
 * @return {string}
 */
proto.netspeak.service.SearchRequest.prototype.getCorpus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.SearchRequest} returns this
 */
proto.netspeak.service.SearchRequest.prototype.setCorpus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 max_phrases = 3;
 * @return {number}
 */
proto.netspeak.service.SearchRequest.prototype.getMaxPhrases = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.SearchRequest} returns this
 */
proto.netspeak.service.SearchRequest.prototype.setMaxPhrases = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PhraseConstraints phrase_constraints = 4;
 * @return {?proto.netspeak.service.PhraseConstraints}
 */
proto.netspeak.service.SearchRequest.prototype.getPhraseConstraints = function() {
  return /** @type{?proto.netspeak.service.PhraseConstraints} */ (
    jspb.Message.getWrapperField(this, proto.netspeak.service.PhraseConstraints, 4));
};


/**
 * @param {?proto.netspeak.service.PhraseConstraints|undefined} value
 * @return {!proto.netspeak.service.SearchRequest} returns this
*/
proto.netspeak.service.SearchRequest.prototype.setPhraseConstraints = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.netspeak.service.SearchRequest} returns this
 */
proto.netspeak.service.SearchRequest.prototype.clearPhraseConstraints = function() {
  return this.setPhraseConstraints(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.netspeak.service.SearchRequest.prototype.hasPhraseConstraints = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.PhraseConstraints.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.PhraseConstraints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.PhraseConstraints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.PhraseConstraints.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequencyMax: jspb.Message.getFieldWithDefault(msg, 1, 0),
    wordsMin: jspb.Message.getFieldWithDefault(msg, 2, 0),
    wordsMax: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.PhraseConstraints}
 */
proto.netspeak.service.PhraseConstraints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.PhraseConstraints;
  return proto.netspeak.service.PhraseConstraints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.PhraseConstraints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.PhraseConstraints}
 */
proto.netspeak.service.PhraseConstraints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrequencyMax(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWordsMin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWordsMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.PhraseConstraints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.PhraseConstraints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.PhraseConstraints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.PhraseConstraints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrequencyMax();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getWordsMin();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getWordsMax();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint64 frequency_max = 1;
 * @return {number}
 */
proto.netspeak.service.PhraseConstraints.prototype.getFrequencyMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.PhraseConstraints} returns this
 */
proto.netspeak.service.PhraseConstraints.prototype.setFrequencyMax = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 words_min = 2;
 * @return {number}
 */
proto.netspeak.service.PhraseConstraints.prototype.getWordsMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.PhraseConstraints} returns this
 */
proto.netspeak.service.PhraseConstraints.prototype.setWordsMin = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 words_max = 3;
 * @return {number}
 */
proto.netspeak.service.PhraseConstraints.prototype.getWordsMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.PhraseConstraints} returns this
 */
proto.netspeak.service.PhraseConstraints.prototype.setWordsMax = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.netspeak.service.Phrase.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.Phrase.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.Phrase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.Phrase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Phrase.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    wordsList: jspb.Message.toObjectList(msg.getWordsList(),
    proto.netspeak.service.Phrase.Word.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.Phrase}
 */
proto.netspeak.service.Phrase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.Phrase;
  return proto.netspeak.service.Phrase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.Phrase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.Phrase}
 */
proto.netspeak.service.Phrase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrequency(value);
      break;
    case 3:
      var value = new proto.netspeak.service.Phrase.Word;
      reader.readMessage(value,proto.netspeak.service.Phrase.Word.deserializeBinaryFromReader);
      msg.addWords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.Phrase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.Phrase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.Phrase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Phrase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getWordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.netspeak.service.Phrase.Word.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.Phrase.Word.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.Phrase.Word.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.Phrase.Word} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Phrase.Word.toObject = function(includeInstance, msg) {
  var f, obj = {
    tag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.Phrase.Word}
 */
proto.netspeak.service.Phrase.Word.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.Phrase.Word;
  return proto.netspeak.service.Phrase.Word.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.Phrase.Word} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.Phrase.Word}
 */
proto.netspeak.service.Phrase.Word.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.netspeak.service.Phrase.Word.Tag} */ (reader.readEnum());
      msg.setTag(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.Phrase.Word.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.Phrase.Word.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.Phrase.Word} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Phrase.Word.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.netspeak.service.Phrase.Word.Tag = {
  WORD: 0,
  WORD_FOR_QMARK: 1,
  WORD_FOR_STAR: 2,
  WORD_IN_DICTSET: 3,
  WORD_IN_ORDERSET: 4,
  WORD_IN_OPTIONSET: 5,
  WORD_FOR_PLUS: 6,
  WORD_FOR_REGEX: 7
};

/**
 * optional Tag tag = 1;
 * @return {!proto.netspeak.service.Phrase.Word.Tag}
 */
proto.netspeak.service.Phrase.Word.prototype.getTag = function() {
  return /** @type {!proto.netspeak.service.Phrase.Word.Tag} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.netspeak.service.Phrase.Word.Tag} value
 * @return {!proto.netspeak.service.Phrase.Word} returns this
 */
proto.netspeak.service.Phrase.Word.prototype.setTag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.netspeak.service.Phrase.Word.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.Phrase.Word} returns this
 */
proto.netspeak.service.Phrase.Word.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.netspeak.service.Phrase.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.Phrase} returns this
 */
proto.netspeak.service.Phrase.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 frequency = 2;
 * @return {number}
 */
proto.netspeak.service.Phrase.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.netspeak.service.Phrase} returns this
 */
proto.netspeak.service.Phrase.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Word words = 3;
 * @return {!Array<!proto.netspeak.service.Phrase.Word>}
 */
proto.netspeak.service.Phrase.prototype.getWordsList = function() {
  return /** @type{!Array<!proto.netspeak.service.Phrase.Word>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.netspeak.service.Phrase.Word, 3));
};


/**
 * @param {!Array<!proto.netspeak.service.Phrase.Word>} value
 * @return {!proto.netspeak.service.Phrase} returns this
*/
proto.netspeak.service.Phrase.prototype.setWordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.netspeak.service.Phrase.Word=} opt_value
 * @param {number=} opt_index
 * @return {!proto.netspeak.service.Phrase.Word}
 */
proto.netspeak.service.Phrase.prototype.addWords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.netspeak.service.Phrase.Word, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.netspeak.service.Phrase} returns this
 */
proto.netspeak.service.Phrase.prototype.clearWordsList = function() {
  return this.setWordsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.netspeak.service.SearchResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.netspeak.service.SearchResponse.ResponseCase = {
  RESPONSE_NOT_SET: 0,
  RESULT: 1,
  ERROR: 2
};

/**
 * @return {proto.netspeak.service.SearchResponse.ResponseCase}
 */
proto.netspeak.service.SearchResponse.prototype.getResponseCase = function() {
  return /** @type {proto.netspeak.service.SearchResponse.ResponseCase} */(jspb.Message.computeOneofCase(this, proto.netspeak.service.SearchResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.SearchResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.SearchResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.SearchResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: (f = msg.getResult()) && proto.netspeak.service.SearchResponse.Result.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.netspeak.service.SearchResponse.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.SearchResponse}
 */
proto.netspeak.service.SearchResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.SearchResponse;
  return proto.netspeak.service.SearchResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.SearchResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.SearchResponse}
 */
proto.netspeak.service.SearchResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.netspeak.service.SearchResponse.Result;
      reader.readMessage(value,proto.netspeak.service.SearchResponse.Result.deserializeBinaryFromReader);
      msg.setResult(value);
      break;
    case 2:
      var value = new proto.netspeak.service.SearchResponse.Error;
      reader.readMessage(value,proto.netspeak.service.SearchResponse.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.SearchResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.SearchResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.SearchResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.netspeak.service.SearchResponse.Result.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.netspeak.service.SearchResponse.Error.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.netspeak.service.SearchResponse.Result.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.SearchResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.SearchResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.SearchResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    phrasesList: jspb.Message.toObjectList(msg.getPhrasesList(),
    proto.netspeak.service.Phrase.toObject, includeInstance),
    unknownWordsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.SearchResponse.Result}
 */
proto.netspeak.service.SearchResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.SearchResponse.Result;
  return proto.netspeak.service.SearchResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.SearchResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.SearchResponse.Result}
 */
proto.netspeak.service.SearchResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.netspeak.service.Phrase;
      reader.readMessage(value,proto.netspeak.service.Phrase.deserializeBinaryFromReader);
      msg.addPhrases(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnknownWords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.SearchResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.SearchResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.SearchResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhrasesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.netspeak.service.Phrase.serializeBinaryToWriter
    );
  }
  f = message.getUnknownWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated Phrase phrases = 1;
 * @return {!Array<!proto.netspeak.service.Phrase>}
 */
proto.netspeak.service.SearchResponse.Result.prototype.getPhrasesList = function() {
  return /** @type{!Array<!proto.netspeak.service.Phrase>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.netspeak.service.Phrase, 1));
};


/**
 * @param {!Array<!proto.netspeak.service.Phrase>} value
 * @return {!proto.netspeak.service.SearchResponse.Result} returns this
*/
proto.netspeak.service.SearchResponse.Result.prototype.setPhrasesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.netspeak.service.Phrase=} opt_value
 * @param {number=} opt_index
 * @return {!proto.netspeak.service.Phrase}
 */
proto.netspeak.service.SearchResponse.Result.prototype.addPhrases = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.netspeak.service.Phrase, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.netspeak.service.SearchResponse.Result} returns this
 */
proto.netspeak.service.SearchResponse.Result.prototype.clearPhrasesList = function() {
  return this.setPhrasesList([]);
};


/**
 * repeated string unknown_words = 2;
 * @return {!Array<string>}
 */
proto.netspeak.service.SearchResponse.Result.prototype.getUnknownWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.netspeak.service.SearchResponse.Result} returns this
 */
proto.netspeak.service.SearchResponse.Result.prototype.setUnknownWordsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.netspeak.service.SearchResponse.Result} returns this
 */
proto.netspeak.service.SearchResponse.Result.prototype.addUnknownWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.netspeak.service.SearchResponse.Result} returns this
 */
proto.netspeak.service.SearchResponse.Result.prototype.clearUnknownWordsList = function() {
  return this.setUnknownWordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.SearchResponse.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.SearchResponse.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.SearchResponse.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.SearchResponse.Error}
 */
proto.netspeak.service.SearchResponse.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.SearchResponse.Error;
  return proto.netspeak.service.SearchResponse.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.SearchResponse.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.SearchResponse.Error}
 */
proto.netspeak.service.SearchResponse.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.netspeak.service.SearchResponse.Error.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.SearchResponse.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.SearchResponse.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.SearchResponse.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.SearchResponse.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.netspeak.service.SearchResponse.Error.Kind = {
  UNKNOWN: 0,
  INTERNAL_ERROR: 1,
  INVALID_PARAMETER: 100,
  INVALID_QUERY: 110,
  INVALID_CORPUS: 111
};

/**
 * optional Kind kind = 1;
 * @return {!proto.netspeak.service.SearchResponse.Error.Kind}
 */
proto.netspeak.service.SearchResponse.Error.prototype.getKind = function() {
  return /** @type {!proto.netspeak.service.SearchResponse.Error.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.netspeak.service.SearchResponse.Error.Kind} value
 * @return {!proto.netspeak.service.SearchResponse.Error} returns this
 */
proto.netspeak.service.SearchResponse.Error.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.netspeak.service.SearchResponse.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.SearchResponse.Error} returns this
 */
proto.netspeak.service.SearchResponse.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Result result = 1;
 * @return {?proto.netspeak.service.SearchResponse.Result}
 */
proto.netspeak.service.SearchResponse.prototype.getResult = function() {
  return /** @type{?proto.netspeak.service.SearchResponse.Result} */ (
    jspb.Message.getWrapperField(this, proto.netspeak.service.SearchResponse.Result, 1));
};


/**
 * @param {?proto.netspeak.service.SearchResponse.Result|undefined} value
 * @return {!proto.netspeak.service.SearchResponse} returns this
*/
proto.netspeak.service.SearchResponse.prototype.setResult = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.netspeak.service.SearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.netspeak.service.SearchResponse} returns this
 */
proto.netspeak.service.SearchResponse.prototype.clearResult = function() {
  return this.setResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.netspeak.service.SearchResponse.prototype.hasResult = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.netspeak.service.SearchResponse.Error}
 */
proto.netspeak.service.SearchResponse.prototype.getError = function() {
  return /** @type{?proto.netspeak.service.SearchResponse.Error} */ (
    jspb.Message.getWrapperField(this, proto.netspeak.service.SearchResponse.Error, 2));
};


/**
 * @param {?proto.netspeak.service.SearchResponse.Error|undefined} value
 * @return {!proto.netspeak.service.SearchResponse} returns this
*/
proto.netspeak.service.SearchResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.netspeak.service.SearchResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.netspeak.service.SearchResponse} returns this
 */
proto.netspeak.service.SearchResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.netspeak.service.SearchResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.CorporaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.CorporaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.CorporaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.CorporaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.CorporaRequest}
 */
proto.netspeak.service.CorporaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.CorporaRequest;
  return proto.netspeak.service.CorporaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.CorporaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.CorporaRequest}
 */
proto.netspeak.service.CorporaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.CorporaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.CorporaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.CorporaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.CorporaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.Corpus.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.Corpus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.Corpus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Corpus.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    language: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.Corpus}
 */
proto.netspeak.service.Corpus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.Corpus;
  return proto.netspeak.service.Corpus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.Corpus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.Corpus}
 */
proto.netspeak.service.Corpus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.Corpus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.Corpus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.Corpus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.Corpus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.netspeak.service.Corpus.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.Corpus} returns this
 */
proto.netspeak.service.Corpus.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.netspeak.service.Corpus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.Corpus} returns this
 */
proto.netspeak.service.Corpus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.netspeak.service.Corpus.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.netspeak.service.Corpus} returns this
 */
proto.netspeak.service.Corpus.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.netspeak.service.CorporaResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.netspeak.service.CorporaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.netspeak.service.CorporaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.netspeak.service.CorporaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.CorporaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    corporaList: jspb.Message.toObjectList(msg.getCorporaList(),
    proto.netspeak.service.Corpus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.netspeak.service.CorporaResponse}
 */
proto.netspeak.service.CorporaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.netspeak.service.CorporaResponse;
  return proto.netspeak.service.CorporaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.netspeak.service.CorporaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.netspeak.service.CorporaResponse}
 */
proto.netspeak.service.CorporaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.netspeak.service.Corpus;
      reader.readMessage(value,proto.netspeak.service.Corpus.deserializeBinaryFromReader);
      msg.addCorpora(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.netspeak.service.CorporaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.netspeak.service.CorporaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.netspeak.service.CorporaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.netspeak.service.CorporaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorporaList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.netspeak.service.Corpus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Corpus corpora = 1;
 * @return {!Array<!proto.netspeak.service.Corpus>}
 */
proto.netspeak.service.CorporaResponse.prototype.getCorporaList = function() {
  return /** @type{!Array<!proto.netspeak.service.Corpus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.netspeak.service.Corpus, 1));
};


/**
 * @param {!Array<!proto.netspeak.service.Corpus>} value
 * @return {!proto.netspeak.service.CorporaResponse} returns this
*/
proto.netspeak.service.CorporaResponse.prototype.setCorporaList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.netspeak.service.Corpus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.netspeak.service.Corpus}
 */
proto.netspeak.service.CorporaResponse.prototype.addCorpora = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.netspeak.service.Corpus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.netspeak.service.CorporaResponse} returns this
 */
proto.netspeak.service.CorporaResponse.prototype.clearCorporaList = function() {
  return this.setCorporaList([]);
};


goog.object.extend(exports, proto.netspeak.service);
